
export const operators = [
    {
        value: 'equal',
        label: ' = ',
        type: 'one',
    },
    {
        value: 'not-equal',
        label: ' != ',
        type: 'one',
    },
    {
        value: 'greater-than',
        label: ' > ',
        type: 'one',
    },
    {
        value: 'greater-or-equal-than',
        label: ' >= ',
        type: 'one',
    },
    {
        value: 'less-than',
        label: ' < ',
        type: 'one',
    },
    {
        value: 'less-or-equal-than',
        label: ' <= ',
        type: 'one',
    },
    {
        value: 'is-checked',
        label: ' is checked ',
        type: 'bool',
    },
    {
        value: 'is-not-checked',
        label: ' is !checked ',
        type: 'bool',
    },
    {
        value: 'contains',
        label: ' contains ',
        type: 'multi',
    },
];
export const features = [
    {
        value: 'required',
        label: 'obbligatorio ...',
    },
    {
        value: 'disabled',
        label: 'disabilitato ...',
    },
    {
        value: 'visibility',
        label: 'visibile ...',
    },
];
